.content{
  background: radial-gradient(circle at left, #d9d5d4 , #ffffff);
  background-size: auto;
  max-width: 1280px;
  /* max-width: 70%; */
  min-height:100vh;
  margin: auto;
}

  .wrapper {
    display: flex;
    flex-direction: row wrap;
    
  }

  .wrapper > * {
    padding: 10px;
    flex: 1 100%;
  }
  
  .text_cont {
    /* background: tomato; */
    margin: 20px;
    padding:20px;
  }
  
  .text_cont .text{
    color: #000;
    font-size: 1.5rem;
    font-weight: 100;
  }

  .img_cont {
    /* background: lightgreen; */
    margin: 20px;
    padding:20px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }

  .img {
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
  }
  
  .content > h1 {
    color: #000;
    font-size: 4rem;
    text-align: center;
  }

  @media all and (min-width: 600px) {
    .text_cont    { flex: 2 0px; }
    .img_cont     { flex: 2 0px; } 
  }
  
  @media all and (min-width: 800px) {
    .text_cont    { flex: 2 0px; }
    .img_cont     { flex: 2 0px; } 
  }
  
