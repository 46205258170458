.contentcontact{
    background: radial-gradient(circle at left, #d9d5d4 , #ffffff);
    background-size: auto;
    max-width: 1280px;

    min-height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin:auto;
  }

  .middle_div{
    display:flex;
    flex-flow: row wrap;
    align-items: stretch;
    align-content: center;
    justify-content: space-evenly;
  }

  .middle_div.taro{
    height:80vh;
  }
  

  .middle_div2{
    display:flex;
    height:50%;
    align-items: stretch;
    justify-content: center;
    width:100%
  }

  .aroundcontact{
    font-size:4rem;
    padding:0.5rem;
    margin:1rem;
  }

  .wrappercontactcol{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    flex: 2 0px;
    padding:0.5rem;
    margin:1rem;

}

.img_content{
    padding:0.5rem;
    margin:1rem;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    flex: 3 0px;
    min-width:50%;
}

.img {
    max-width: 100%;
    max-height: 100%;
    justify-content: center;

  }

  .text{
    text-align:center;
  }


  .row{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:0.5rem;
  }

  .row i.fa {
    flex-grow:1;
    padding:0.5rem;
  }

  .row i.fa::before {
    flex-grow:1;
    font-size:3rem;
  }

  .row p.text {
    flex-grow:5;
    font-size:1.25rem;
    padding:0.5rem;
  }

  iframe{
    padding:0.5rem;
    margin:1rem;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
  }