.content .gite{
    background: radial-gradient(circle at left, #d9d5d4 , #ffffff);

}


.wrapper {
    display: flex;
    flex-direction: row wrap;
    flex-wrap: wrap;
    align-items: flex-start;
    /* margin:20px; */

  }

  #first{
    align-items: center;
    margin:20px;
  }
  /* .wrapper > * {
    padding: 10px;
    flex: 1 100%;

  } */

  .wrapper3 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .wrapper3 > * {
    padding: 10px;
    /* flex:  100%; */
  }
  
  .text_cont {
    /* background: tomato; */
    margin: 20px;
    padding:20px;
  }

  .cont3 {
    /* background: tomato; */
    margin: 1%;
    padding:1%;

    /* width:30%; */
  }
  
  .text{
    color: #000;
    font-size: 1.5rem;
    font-weight: 100;
  }

  .img_cont {
    /* background: lightgreen; */
    margin: 1%;
    padding:1%;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }

  .img {
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);

  }
  
  .contentg > h1 {
    color: #000;
    font-size: 4rem;
    text-align: center;
  }

  /* @media all and (min-width: 600px) {
    .text_cont    { flex: 2 0px; }
    .cont3    { flex: 1 1 30%; }
    .img_cont     { flex: 2 0px; } 
  } */
  
  @media screen and (min-width: 1200px) {
    .text_cont    { flex: 2 0px; }
    .cont3    { flex: 1 1 30%; }
    .img_cont     { flex: 2 0px; } 
  }
  
