.contenttaro{
    background: radial-gradient(circle at left, #d9d5d4 , #ffffff);
    background-size: auto;
    max-width: 1280px;

    min-height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin:auto;
  }

  .middle_divtaro{
    display:flex;
    flex-flow: row wrap;
    align-items: stretch;
    align-content: flex-start;
    justify-content: space-evenly;
    min-height:80vh;
  }

  .aroundtaro{
    font-size:2rem;
    padding:0.5rem;
    margin:1rem;
  }

  .wrappercontactcol{
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: center;
    justify-content: center;
    flex: 2 0px;
    padding:0.5rem;
    margin:1rem;

}

.img_content_taro{
    padding:0.5rem;
    margin:1rem;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    flex: 3 0px;
    min-width:60%;
}

.img {
    min-width: 100%;
    max-height: 100%;
    justify-content: center;

  }

  .text{
    text-align:left;
  }

  .aroundtaro {
    color: #000;
    font-size: 4rem;
    text-align: center;
  }

  .row{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin:0.5rem;
  }

  .row i.fa {
    flex-grow:1;
    padding:0.5rem;
  }

  .row i.fa::before {
    flex-grow:1;
    font-size:3rem;
  }

  .row p.text {
    flex-grow:5;
    font-size:1.25rem;
    padding:0.5rem;
  }

  .center iframe{
    width: 85%;
    min-height: 50vh;
    padding:0.5rem;
    margin:1rem;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
  }

  .center{
    display:flex;
    justify-content:center;
    width:100%;
    padding:0.5rem;
    margin:0.5rem;
}

.buttonbottom{
  padding:0.5rem;
  margin:0.5rem;
}